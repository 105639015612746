var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          { staticClass: "align-self-center" },
          [
            _c("base-input", {
              staticClass: "search-user-input",
              attrs: { placeholder: "Search team...", type: "search" },
              model: {
                value: _vm.filter,
                callback: function ($$v) {
                  _vm.filter = $$v
                },
                expression: "filter",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "align-self-center ml-auto" },
          [
            _c(
              "b-button",
              {
                staticClass: "text-uppercase mr-2",
                attrs: { size: "sm", variant: "outline-primary" },
                on: { click: _vm.onNewTeamClick },
              },
              [_vm._v("\n        Create Team\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12 my-2" } },
            [
              _c(
                "b-card",
                {
                  attrs: {
                    "body-class": "management-card-body",
                    title: "Teams",
                  },
                },
                [
                  _vm.allTeams
                    ? _c("action-table", {
                        key: _vm.reloadActionTable,
                        attrs: {
                          items: _vm.allTeams,
                          fields: _vm.fields,
                          perPage: 5,
                          filter: _vm.filter,
                          hasActions: "",
                          actionTooltip: "name",
                        },
                        on: {
                          itemClicked: _vm.onItemClick,
                          itemDeleted: _vm.onItemDelete,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            "hide-header": "",
            "hide-footer": "",
            "content-class": "team-edit-modal",
          },
          model: {
            value: _vm.showNewModal,
            callback: function ($$v) {
              _vm.showNewModal = $$v
            },
            expression: "showNewModal",
          },
        },
        [
          _c("team-form", {
            key: "new-team-form",
            on: { itemSaved: _vm.onItemSave, itemCancelled: _vm.onItemCancel },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            "hide-header": "",
            "hide-footer": "",
            "content-class": "team-edit-modal",
          },
          model: {
            value: _vm.showEditModal,
            callback: function ($$v) {
              _vm.showEditModal = $$v
            },
            expression: "showEditModal",
          },
        },
        [
          _c("team-form", {
            key: "edit-team-form",
            attrs: { editMode: "", teamId: _vm.selectedTeamId },
            on: { itemSaved: _vm.onItemSave, itemCancelled: _vm.onItemCancel },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("action-confirm-modal", {
        attrs: {
          actionButton: "Delete",
          actionButtonVariant: "danger",
          showModal: _vm.showConfirmModal,
          processing: _vm.processing,
        },
        on: {
          "update:showModal": function ($event) {
            _vm.showConfirmModal = $event
          },
          "update:show-modal": function ($event) {
            _vm.showConfirmModal = $event
          },
          modalTrigger: _vm.onModalTrigger,
          actionConfirmed: _vm.onActionConfirm,
          actionCanceled: _vm.onActionCancel,
        },
        scopedSlots: _vm._u(
          [
            _vm.deletedTeam
              ? {
                  key: "body",
                  fn: function () {
                    return [
                      _c("h6", [
                        _vm._v("\n        Are you sure you want to delete "),
                        _c("b", [_vm._v(_vm._s(_vm.deletedTeam.name))]),
                        _vm._v("?\n      "),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("This action cannot be undone once confirmed."),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }