<template>
  <div>
    <div class="d-flex">
      <div class="align-self-center">
        <base-input
          placeholder="Search team..."
          v-model="filter"
          type="search"
          class="search-user-input"
        ></base-input>
      </div>
      <div class="align-self-center ml-auto">
        <b-button
          size="sm"
          variant="outline-primary"
          class="text-uppercase mr-2"
          @click="onNewTeamClick"
        >
          Create Team
        </b-button>
      </div>
    </div>
    <b-row>
      <b-col cols="12 my-2">
        <b-card body-class="management-card-body" title="Teams">
          <action-table
            v-if="allTeams"
            :key="reloadActionTable"
            :items="allTeams"
            :fields="fields"
            :perPage="5"
            :filter="filter"
            hasActions
            actionTooltip="name"
            @itemClicked="onItemClick"
            @itemDeleted="onItemDelete"
          ></action-table>
        </b-card>
      </b-col>
    </b-row>

    <!-- Create Team -->
    <b-modal
      v-model="showNewModal"
      size="md"
      hide-header
      hide-footer
      content-class="team-edit-modal"
    >
      <team-form
        key="new-team-form"
        @itemSaved="onItemSave"
        @itemCancelled="onItemCancel"
      ></team-form>
    </b-modal>

    <!-- Edit Team -->
    <b-modal
      v-model="showEditModal"
      size="md"
      hide-header
      hide-footer
      content-class="team-edit-modal"
    >
      <team-form
        key="edit-team-form"
        editMode
        :teamId="selectedTeamId"
        @itemSaved="onItemSave"
        @itemCancelled="onItemCancel"
      ></team-form>
    </b-modal>

    <!-- Confirm Action -->
    <action-confirm-modal
      actionButton="Delete"
      actionButtonVariant="danger"
      :showModal.sync="showConfirmModal"
      :processing="processing"
      @modalTrigger="onModalTrigger"
      @actionConfirmed="onActionConfirm"
      @actionCanceled="onActionCancel"
    >
      <template v-if="deletedTeam" v-slot:body>
        <h6>
          Are you sure you want to delete <b>{{ deletedTeam.name }}</b
          >?
        </h6>
        <p>This action cannot be undone once confirmed.</p>
      </template>
    </action-confirm-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ActionTable from '@/components/Tables/ActionTable.vue'
import BaseInput from '@/components/Common/BaseInput.vue'
import TeamForm from '@/components/Form/TeamForm.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import ActionConfirmModal from '@/components/Modals/ActionConfirmModal.vue'
import { teamFields } from '@/data/admin'
import variables from '@/assets/css/sass/themes/_perform.sim.scss'
export default {
  components: {
    ActionTable,
    BaseInput,
    TeamForm,
    PulseLoader,
    ActionConfirmModal
  },
  data() {
    return {
      fields: teamFields,
      filter: null,
      showNewModal: false,
      showEditModal: false,
      showConfirmModal: false,
      selectedTeamId: null,
      deletedTeam: null,
      reloadActionTable: 0,
      loading: false,
      fill: variables.primaryColor,
      processing: false
    }
  },
  methods: {
    ...mapActions(['fetchTeams', 'deleteTeam']),
    async getTeams() {
      let self = this
      self.loading = true
      await self.fetchTeams()
      self.loading = false
    },
    onNewTeamClick() {
      let self = this
      self.showNewModal = true
    },
    onItemClick(item) {
      let self = this
      self.selectedTeamId = item.id
      self.showEditModal = true
    },
    async onItemDelete(item) {
      let self = this
      self.deletedTeam = item
      self.onModalTrigger(true)
    },
    async onItemSave(value) {
      let self = this
      self.showNewModal = false
      self.showEditModal = false
      await self.getTeams()
      self.reloadActionTable += 1
    },
    onItemCancel() {
      let self = this
      self.showNewModal = false
      self.showEditModal = false
    },
    async onActionConfirm() {
      let self = this
      let item = self.deletedTeam
      self.processing = true
      const deletedTeam = { id: item.id, name: item.name }
      await self.deleteTeam(deletedTeam)
      self.processing = false
      self.onModalTrigger(false)
      self.reloadTeam()
    },
    async onModalTrigger(value) {
      this.showConfirmModal = value
    },
    onActionCancel() {
      this.onModalTrigger(false)
    },
    reloadTeam() {
      let self = this
      self.getTeams()
      self.reloadActionTable += 1
    }
  },
  computed: {
    ...mapGetters(['allTeams'])
  },
  async created() {
    let self = this
    await self.getTeams()
  }
}
</script>
