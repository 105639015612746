import BaseInput from '@/components/Common/BaseInput.vue'
import BaseSelection from '@/components/Selections/BaseSelection.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import { isPasswordValid, errorMessages } from '@/utils/validators'
import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
import { mapActions } from 'vuex'
export const managementMixin = {
  components: {
    BaseInput,
    BaseSelection,
    ProcessingButton,
    PulseLoader
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      errorMessages,
      processing: false,
      formChanged: false,
      teams: []
    }
  },
  methods: {
    ...mapActions(['fetchTeams', 'fetchAllTeams']),
    async getTeams() {
      let self = this
      await self.fetchTeams()
    },
    async getAllTeams() {
      let self = this
      await self.fetchAllTeams()
    },
    setError(item, name) {
      if (item.$dirty) {
        let self = this
        let errorMessage = ''
        let errorMessages = self.errorMessages
        if (item.required === false) {
          errorMessage = `${name} ${errorMessages.required}`
          return errorMessage
        }

        if (item.email === false) {
          errorMessage = `${name} ${errorMessages.email}`
          return errorMessage
        }

        if (item.emailMinLength === false) {
          errorMessage = `${name} ${errorMessages.emailMinLength}`
          return errorMessage
        }

        if (item.passwordMaxLength === false) {
          errorMessage = `${name} ${errorMessages.passwordMaxLength}`
          return errorMessage
        }

        if (item.passwordMinLength === false) {
          errorMessage = `${name} ${errorMessages.passwordMinLength}`
          return errorMessage
        }

        if (item.isPasswordValid === false) {
          errorMessage = `${errorMessages.isPasswordValid}`
          return errorMessage
        }

        if (item.samePassword === false) {
          errorMessage = `${name} ${errorMessages.samePassword}`
          return errorMessage
        }
      }
    },
    async onItemSave(action, actionItem, successMessage) {
      let self = this
      await self.$store
        .dispatch(action, actionItem)
        .then((response) => {
          self.processing = false
          self.formChanged = false
          self.$notify(response.status, successMessage, response.message, {
            duration: 2000,
            permanent: false
          })
          self.$emit('itemSaved', true)
        })
        .catch((error) => {
          self.processing = false
          self.$notify('error', error, null, {
            duration: 3000,
            permanent: false
          })
          NProgress.done()
        })
    },
    onCancelClick() {
      let self = this
      self.$emit('itemCancelled')
    }
  }
}
