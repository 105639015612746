var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onTeamSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "col-12 mb-4 text-center" },
            [
              _c("b-avatar", {
                staticClass: "mb-2 avatar-edit",
                attrs: {
                  button: "",
                  "badge-variant": "transparent",
                  variant: "light",
                  size: "5em",
                  src: _vm.avatarUrl,
                },
                on: { click: _vm.onAvatarClick },
                scopedSlots: _vm._u(
                  [
                    _vm.avatarUrl
                      ? {
                          key: "badge",
                          fn: function () {
                            return [
                              _c("i", { staticClass: "simple-icon-camera" }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _vm.newTeam.name
                ? _c("h5", [_vm._v(_vm._s(_vm.newTeam.name))])
                : _c("h5", [_vm._v("[Name]")]),
              _vm._v(" "),
              _c("input", {
                ref: "avatarFile",
                attrs: {
                  id: "fileUpload",
                  type: "file",
                  accept: "image/svg",
                  hidden: "",
                },
                on: { change: _vm.onAvatarFileChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "col-md-12 mb-3" },
            [
              _c("base-input", {
                key: "name-input",
                attrs: {
                  id: "name-input",
                  label: "Name:",
                  placeholder: "Name",
                  validate: "",
                  required: "",
                  v: _vm.$v.newTeam.name,
                  error: _vm.setError(_vm.$v.newTeam.name, "Name"),
                  loading: _vm.loading,
                },
                model: {
                  value: _vm.newTeam.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.newTeam, "name", $$v)
                  },
                  expression: "newTeam.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          { staticClass: "align-self-center ml-auto" },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "light", size: "sm" },
                on: { click: _vm.onCancelClick },
              },
              [_c("span", { staticClass: "label" }, [_vm._v("Cancel")])]
            ),
            _vm._v(" "),
            _c("processing-button", {
              staticClass: "ml-2",
              attrs: {
                variant: "primary",
                size: "sm",
                label: _vm.editMode ? "Update" : "Save",
                processing: _vm.processing,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }