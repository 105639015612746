<template>
  <b-form @submit.prevent="onTeamSubmit">
    <b-row>
      <b-col class="col-12 mb-4 text-center">
        <b-avatar
          button
          class="mb-2 avatar-edit"
          badge-variant="transparent"
          variant="light"
          size="5em"
          :src="avatarUrl"
          @click="onAvatarClick"
        >
          <template #badge v-if="avatarUrl">
            <i class="simple-icon-camera"></i>
          </template>
        </b-avatar>
        <h5 v-if="newTeam.name">{{ newTeam.name }}</h5>
        <h5 v-else>[Name]</h5>
        <input
          id="fileUpload"
          ref="avatarFile"
          type="file"
          accept="image/svg"
          @change="onAvatarFileChange"
          hidden
        />
      </b-col>
      <b-col class="col-md-12 mb-3">
        <base-input
          id="name-input"
          key="name-input"
          label="Name:"
          placeholder="Name"
          v-model="newTeam.name"
          validate
          required
          :v="$v.newTeam.name"
          :error="setError($v.newTeam.name, 'Name')"
          :loading="loading"
        ></base-input>
      </b-col>
    </b-row>
    <div class="d-flex">
      <div class="align-self-center ml-auto">
        <b-button variant="light" size="sm" @click="onCancelClick">
          <span class="label">Cancel</span>
        </b-button>
        <processing-button
          variant="primary"
          size="sm"
          :label="editMode ? 'Update' : 'Save'"
          class="ml-2"
          :processing="processing"
        ></processing-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { managementMixin } from '@/mixins/managementMixin'
export default {
  props: {
    teamId: {
      type: Number,
      default: null
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  mixins: [validationMixin, managementMixin],
  data() {
    return {
      newTeam: this.createNewTeam(),
      newTeamForm: this.createNewTeamForm(),
      avatarUrl: ''
    }
  },
  validations: {
    newTeam: {
      name: { required }
    }
  },
  methods: {
    ...mapActions(['fetchTeam', 'addTeam', 'updateTeam']),
    createNewTeam() {
      return {
        id: null,
        name: '',
        avatar: '',
        workComplexity: 2,
        approvalCodeValidation: 1
      }
    },
    createNewTeamForm() {
      return new FormData()
    },
    async getTeam() {
      let self = this
      self.loading = true
      const team = self.allTeams.find(team => team.id === self.teamId)
      self.loading = false
      if (self.editMode) {
        self.newTeam.id = self.teamId
        self.newTeam.name = team.name
        self.newTeam.avatar = team.avatar
        self.avatarUrl = team.avatar
      }
    },
    async onTeamSubmit() {
      let self = this
      self.$v.newTeam.$touch()

      // return if form error is captured
      if (self.$v.newTeam.$pending || self.$v.newTeam.$error) return

      // submit form
      let team = self.newTeam
      let teamForm = self.newTeamForm

      teamForm.append('name', team.name)

      if (self.editMode) {
        teamForm.append('id', team.id)
      }

      if (self.avatarUrl && self.isAvatarUrlLocal) {
        teamForm.append('avatar', team.avatar)
      }

      teamForm.append('approval_code', team.approvalCodeValidation)
      teamForm.append('work_complexity', team.workComplexity)

      self.processing = true
      if (self.editMode) {
        await self.updateTeam(teamForm)
      } else {
        await self.addTeam(teamForm)
      }

      self.processing = false
      self.formChanged = false
      self.$emit('itemSaved', true)
    },
    onAvatarClick() {
      this.$refs['avatarFile'].click()
    },
    onAvatarFileChange(event) {
      let self = this
      let files = event.target.files
      const file = files[0]
      self.newTeam.avatar = file
      self.avatarUrl = URL.createObjectURL(file)
    },
    async resetTeamData() {
      let self = this
      self.newTeam = self.createNewTeam()
      self.$v.$reset()
    }
  },
  computed: {
    ...mapGetters(['allTeams']),
    isAvatarUrlLocal() {
      let self = this
      let url = self.avatarUrl
      let regex = /^blob:/
      return regex.test(url)
    }
  },
  async created() {
    let self = this
    if (self.editMode) {
      self.getTeam()
    }
  },
  watch: {
    selectedTeamType(newVal) {
      this.newTeam.teamType = newVal.id
    }
  }
}
</script>
